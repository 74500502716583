import { RouteProp, useNavigation } from '@react-navigation/native';
import { useQueryClient } from '@tanstack/react-query';
import { SPACING } from 'base/src/ui/CommonStyles';
import { LanguageDialog } from 'base/src/ui/dialogs/LanguageDialog';
import { useDialog } from 'base/src/ui/helpers/DialogHelper';
import { useErrorHelper } from 'base/src/ui/helpers/ErrorHelper';
import { useFontConfig } from 'base/src/ui/hooks/useFontConfig';
import { useTranslate } from 'base/src/ui/locale/locale';
import { loggerWithTag } from 'base/src/utils/log';
import { HawkerUserRepo } from 'ehawker/src/data/repositories/HawkerUserRepo';
import { useHawkerUser } from 'ehawker/src/ui/hooks/useHawkerUser';
import { navButtonsAtom } from 'ehawker/src/ui/hooks/useNavButtons';
import { LocaleLabels, TranslationKeys } from 'ehawker/src/ui/locale/locale';
import { Image } from 'expo-image';
import { atom, useAtomValue } from 'jotai';
import _ from 'lodash';
import React, { memo, useState } from 'react';
import { StyleSheet } from 'react-native';
import {
  Appbar,
  MD2Colors,
  Menu,
  SegmentedButtons,
  Text,
  useTheme,
} from 'react-native-paper';

import { NavigationProps, RouteNames, ROUTES } from './Routes';
import { deleteData } from '../../data/actions/DeleteData';
import { fetchData } from '../../data/actions/FetchData';
import { FLAG } from '../../utils/flag';
import { eventEmitter } from '../App';
import { useAppRoutes } from '../hooks/useAppRoutes';
import { dashboardTitleAtom } from '../hooks/useDashboardTitle';
import { useDrawer } from '../pages/Dashboard/drawer/useDrawer';

const log = loggerWithTag('NavBar.tsx');

export const navColorAtom = atom('');

const styles = StyleSheet.create({
  appbarContent: {
    textAlign: 'left',
    alignSelf: 'flex-start',
  },
});

export type NavBarProps = {
  navigation: any;
  route: RouteProp<Record<string, object | undefined>, string>;
  options: any;
  back?: { title: string };
};

type MenuItem = 'refresh' | 'logout';

function DashboardIcon() {
  const user = useHawkerUser();
  return (
    <>
      <Image
        contentFit="contain"
        contentPosition={{
          top: 0,
          left: 0,
        }}
        source={require('../../../assets/common/logo.png')}
        style={{
          height: 20,
          width: '100%',
          marginBottom: SPACING.xs,
        }}
      />
      <Text
        variant="labelLarge"
        style={{
          fontWeight: 'bold',
          color: MD2Colors.blue800,
        }}
      >
        {user?._store}
      </Text>
    </>
  );
}

export const NavBar = memo(
  function NavBar({ route, options }: NavBarProps) {
    log.debug(`NavBar rendered -> ${route.name}`);

    const navButtons = useAtomValue(navButtonsAtom);
    const { getRouteByName } = useAppRoutes();
    const queryClient = useQueryClient();
    const dashboardTitle = useAtomValue(dashboardTitleAtom);
    const { t } = useTranslate();
    const { errorSnackbar } = useErrorHelper();
    const { setDrawerOpen } = useDrawer();
    const navigation = useNavigation<NavigationProps>();
    const currentRoute = getRouteByName(route.name);
    const isDashboard = currentRoute === ROUTES.DashboardPage;
    const title = currentRoute?.title ? getTitle(currentRoute.title) : '';
    const [languageDialogVisible, setLanguageDialogVisible] = useState(false);
    const [menuVisible, setMenuVisible] = useState(false);
    const navColor = useAtomValue(navColorAtom);
    const { colors } = useTheme();
    const { fontMultiplier, setFontMultiplier } = useFontConfig();
    const { addDialog } = useDialog();

    function getTitle(routeTitle: TranslationKeys) {
      if (routeTitle === ROUTES.DashboardPage.title) {
        if (!_.isEmpty(dashboardTitle)) {
          return dashboardTitle;
        }
        return <DashboardIcon />;
      }
      return routeTitle ? t(routeTitle) : '';
    }

    function goBack() {
      if (navigation.canGoBack()) {
        navigation.goBack();
      } else {
        navigation.navigate(RouteNames.Dashboard);
      }
    }

    return (
      <>
        <Appbar.Header
          style={{ backgroundColor: navColor || colors.elevation.level2 }}
          statusBarHeight={0}
        >
          {currentRoute?.backButton && <Appbar.BackAction onPress={goBack} />}
          {isDashboard && FLAG.DRAWER && !currentRoute?.backButton && (
            <Appbar.Action
              icon="menu"
              onPress={() => setDrawerOpen((prev) => !prev)}
            />
          )}
          {/*@ts-ignore*/}
          <Appbar.Content titleStyle={styles.appbarContent} title={title} />
          {FLAG.TRANSLATION && (
            <Appbar.Action icon="web" onPress={languageClicked} />
          )}
          {navButtons.map((button, index) => (
            <Appbar.Action
              key={index}
              icon={button.icon}
              onPress={button.onClick}
            />
          ))}
          {isDashboard && (
            <Menu
              visible={menuVisible}
              onDismiss={() => setMenuVisible(false)}
              anchor={
                <Appbar.Action icon="dots-vertical" onPress={menuClicked} />
              }
            >
              <Menu.Item
                title={t('refresh')}
                onPress={() => menuItemClicked('refresh')}
              />
              <Menu.Item title={t('font_size')} />
              <SegmentedButtons
                style={{
                  marginHorizontal: SPACING.s,
                }}
                theme={{
                  colors: {
                    secondaryContainer: colors.primary,
                    onSecondaryContainer: colors.onPrimary,
                  },
                }}
                value={fontMultiplier.toString()}
                onValueChange={(value) => setFontMultiplier(parseFloat(value))}
                buttons={[
                  {
                    value: '1',
                    label: t('small'),
                  },
                  {
                    value: '1.2',
                    label: t('large'),
                  },
                ]}
              />
              {/*<Menu.Item*/}
              {/*  title={t('tutorial_video')}*/}
              {/*  onPress={async () => {*/}
              {/*    await Linking.openURL(CONSTANTS.ORDER_TUTORIAL_URL);*/}
              {/*  }}*/}
              {/*/>*/}
              <Menu.Item
                title={t('logout')}
                onPress={() => menuItemClicked('logout')}
              />
            </Menu>
          )}
        </Appbar.Header>
        <LanguageDialog
          localeLabels={LocaleLabels}
          visible={languageDialogVisible}
          onDialogClosed={() => setLanguageDialogVisible(false)}
        />
      </>
    );

    function languageClicked() {
      log.info('language button clicked');
      setLanguageDialogVisible(true);
    }

    function menuClicked() {
      log.info('menu button clicked');
      setMenuVisible(true);
    }

    // TODO: add a custom hook to add menu item which utilize useEffect
    //  for clearing the menu after unmount
    function menuItemClicked(item: MenuItem) {
      log.info(`menu item clicked: ${item}`);
      setMenuVisible(false);
      switch (item) {
        case 'refresh':
          refreshClicked();
          break;
        case 'logout':
          logoutClicked();
          break;
      }
    }

    function refreshClicked() {
      log.info('refresh clicked');
      fetchData().catch(errorSnackbar);
      eventEmitter.emit('nav_refresh');
      queryClient.invalidateQueries();
    }

    function logoutClicked() {
      log.info('logout clicked');
      addDialog({
        title: t('confirmation'),
        message: t('confirm_logout'),
        primaryButtonAction: async () => {
          await HawkerUserRepo.logout().catch(log.error);
          await deleteData().catch(log.error);
        },
        secondaryButton: t('cancel'),
      });
    }
  },
  (prev, next) => {
    return (
      prev.options?.title === next.options?.title &&
      prev.route?.name === next.route?.name
    );
  },
);
