import { NavigationProp } from '@react-navigation/native';
import { TranslationKeys } from 'ehawker/src/ui/locale/locale';
import { OrderDetailPage } from 'ehawker/src/ui/pages/Order/OrderDetail/OrderDetailPage';

import DashboardPage from '../pages/Dashboard/drawer/DashboardPage';
import LoginPage from '../pages/Login/LoginPage';

export enum RouteNames {
  Dashboard = 'DashboardPage',
  Login = 'Login',
  NewOrder = 'NewOrder',
  OrderDetail = 'OrderDetail',
  DoDetail = 'DoDetail',
}

type DefinedRouteParams = {
  [RouteNames.OrderDetail]: {
    orderSn: number;
    doSn: number;
    similarOrderSn: number;
  };
  [RouteNames.DoDetail]: {
    doSn: number;
  };
  [RouteNames.NewOrder]: {
    stcode?: string;
  };
};

type EmptyRouteParams = {
  [key in Exclude<RouteNames, keyof DefinedRouteParams>]: undefined;
};

export type RouteParams = EmptyRouteParams & DefinedRouteParams;

export type Route = {
  name: RouteNames;
  title: TranslationKeys | '';
  component: (props: any) => JSX.Element;
  backButton: boolean;
  authRequired: boolean;
};

export const ROUTES: Record<RouteNames, Route> = {
  [RouteNames.Login]: {
    name: RouteNames.Login,
    title: '',
    component: LoginPage,
    backButton: false,
    authRequired: false,
  },
  [RouteNames.Dashboard]: {
    name: RouteNames.Dashboard,
    title: 'dashboard',
    component: DashboardPage,
    backButton: false,
    authRequired: true,
  },
  [RouteNames.NewOrder]: {
    name: RouteNames.NewOrder,
    title: 'new_order',
    component: OrderDetailPage,
    backButton: true,
    authRequired: true,
  },
  [RouteNames.OrderDetail]: {
    name: RouteNames.OrderDetail,
    title: 'order_details',
    component: OrderDetailPage,
    backButton: true,
    authRequired: true,
  },
  [RouteNames.DoDetail]: {
    name: RouteNames.DoDetail,
    title: 'do_details',
    component: OrderDetailPage,
    backButton: true,
    authRequired: true,
  },
};

export type NavigationProps = NavigationProp<RouteParams>;
