import { useNavigation } from '@react-navigation/native';
import { SPACING } from 'base/src/ui/CommonStyles';
import { useTranslate } from 'base/src/ui/locale/locale';
import { RecentOrderCard } from 'ehawker/src/ui/pages/Order/RecentOrder/RecentOrderCard';
import { useHawkerDashboard } from 'ehawker/src/ui/query/useHawkerDashboard';
import _ from 'lodash';
import { FlatList } from 'react-native';
import { Text } from 'react-native-paper';

import { NavigationProps, RouteNames } from '../../../navigation/Routes';

export function PendingDeliverySection() {
  const { t } = useTranslate();
  const navigation = useNavigation<NavigationProps>();
  const { hawkerDashboard } = useHawkerDashboard();

  if (
    _.isNil(hawkerDashboard) ||
    hawkerDashboard?.pendingDelivery.length === 0
  ) {
    return <></>;
  }
  return (
    <>
      <Text
        variant="bodyMedium"
        style={{
          fontWeight: 'bold',
          marginBottom: 10,
        }}
      >
        {t('pending_delivery')}
      </Text>
      <FlatList
        horizontal
        style={{
          margin: -SPACING.s,
        }}
        contentContainerStyle={{
          padding: SPACING.s,
        }}
        keyExtractor={(item) => String(item._osn)}
        renderItem={({ item }) => (
          <RecentOrderCard
            order={item}
            onSelected={() => {
              if (_.isNil(item._osn)) return;
              navigation.navigate(RouteNames.OrderDetail, {
                orderSn: item._osn,
              });
            }}
          />
        )}
        data={hawkerDashboard?.pendingDelivery}
      />
    </>
  );
}
