import { createDrawerNavigator } from '@react-navigation/drawer';
import { loggerWithTag } from 'base/src/utils/log';
import * as SplashScreen from 'expo-splash-screen';
import React, { useEffect } from 'react';

import { DashboardDrawer } from './DashboardDrawer';
import { DrawerItems, useDrawer } from './useDrawer';

const log = loggerWithTag('DashboardPage.tsx');

const Drawer = createDrawerNavigator();

export default function DashboardPage() {
  log.debug('DashboardPage rendered');

  useEffect(() => {
    SplashScreen.hideAsync().catch(log.error);
  }, []);

  const { selectedDrawerItem } = useDrawer();
  const SelectedDrawerItemComponent = DrawerItems[selectedDrawerItem].component;

  return (
    <>
      <DashboardDrawer />
      <SelectedDrawerItemComponent />
    </>
  );
}
