import { zodResolver } from '@hookform/resolvers/zod';
import { CustomError, CustomErrorType } from 'base/src/data/CustomError';
import { CommonStyles } from 'base/src/ui/CommonStyles';
import { PasswordInput } from 'base/src/ui/components/input/PasswordInput';
import { useErrorHelper } from 'base/src/ui/helpers/ErrorHelper';
import { useTranslate } from 'base/src/ui/locale/locale';
import { HawkerUserRepo } from 'ehawker/src/data/repositories/HawkerUserRepo';
import { UserRepo } from 'ehawker/src/data/repositories/UserRepo';
import {
  LoginRequestSchema,
  LoginRequestZodSchema,
} from 'ehawker/src/data/schemas/auth/LoginRequestSchema';
import { useDeviceId } from 'ehawker/src/ui/hooks/useDeviceId';
import React, { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { StyleSheet, View } from 'react-native';
import {
  Button,
  Checkbox,
  HelperText,
  Text,
  TextInput,
  useTheme,
} from 'react-native-paper';

import { loginStyles } from './LoginPage';

export function LoginForm() {
  const { t } = useTranslate();
  const { colors } = useTheme();
  const deviceId = useDeviceId();
  const { errorDialog } = useErrorHelper();
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const form = useForm<LoginRequestSchema>({
    resolver: zodResolver(LoginRequestZodSchema),
  });

  const styles = StyleSheet.create({
    rememberMeContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: 10, // Adjust as needed
    },
  });

  useEffect(() => {
    (async () => {
      const storedCredentials: LoginRequestSchema =
        await UserRepo.getLoginCredentials();
      if (storedCredentials) {
        // Use default values ('') if stored credentials are undefined
        form.setValue('_userName', storedCredentials._userName ?? '');
        form.setValue('_password', storedCredentials._password ?? '');
      }
    })();
  }, []);

  return (
    <FormProvider {...form}>
      <Controller
        control={form.control}
        name="_userName"
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            <TextInput
              keyboardType="numeric"
              style={loginStyles.textInput}
              mode="flat"
              label={t('username')}
              value={value}
              error={!!error}
              onPressIn={() => form.clearErrors('_userName')}
              onFocus={() => form.clearErrors('_userName')}
              disabled={loading}
              onChangeText={onChange}
              left={<TextInput.Icon icon="account-circle" />}
            />
            {!!error && (
              <HelperText type="error">{t('field_required')}</HelperText>
            )}
          </>
        )}
      />
      <Controller
        control={form.control}
        name="_password"
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <PasswordInput
            style={loginStyles.textInput}
            mode="flat"
            label={t('password')}
            value={value}
            inputError={error}
            onPressIn={() => form.clearErrors('_password')}
            onFocus={() => form.clearErrors('_password')}
            disabled={loading}
            onChangeText={onChange}
            left={<TextInput.Icon icon="lock" />}
          />
        )}
      />
      <View style={styles.rememberMeContainer}>
        <Checkbox.Android
          status={rememberMe ? 'checked' : 'unchecked'}
          onPress={() => setRememberMe(!rememberMe)}
          color={colors.primary}
        />
        <Text>{t('remember_me')}</Text>
      </View>
      <Button
        style={[loginStyles.loginBtn, CommonStyles.container]}
        mode="contained"
        loading={loading}
        disabled={loading}
        onPress={async () => {
          setLoading(true);
          try {
            const isValid = await form.trigger();
            if (!isValid) {
              setLoading(false);
              return;
            }
            await HawkerUserRepo.login(form.getValues());
          } catch (e) {
            const err = e as Error;
            errorDialog(
              new CustomError(
                CustomErrorType.RESPONSE_ERROR,
                `${err.message} \n\n${t('device_id')}: ${deviceId}`,
              ),
            );
          } finally {
            const credentials = {
              _userName: rememberMe ? form.getValues('_userName') : '',
              _password: rememberMe ? form.getValues('_password') : '',
            };
            await UserRepo.setLoginCredentials(credentials);
            setLoading(false);
          }
        }}
      >
        {t('login')}
      </Button>
    </FormProvider>
  );
}
