import 'base/assets/common/styles/fonts.css';

import 'react-native-gesture-handler';
import { startUp } from 'base';
import { registerRootComponent } from 'expo';

import { App } from './src/ui/App';

registerRootComponent(App);
startUp();
