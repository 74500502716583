import { useUser } from 'ehawker/src/ui/hooks/useUser';
import _ from 'lodash';

import { Route, RouteNames, ROUTES } from '../navigation/Routes';

export function useAppRoutes() {
  const user = useUser();
  const routes = Object.values(ROUTES)
    .filter((route) => !_.isEmpty(route.name))
    .filter((route) => !user || route.name !== RouteNames.Login)
    .map((route) => {
      if (route.authRequired && !user) {
        return {
          ...ROUTES.Login,
          name: route.name,
        };
      } else if (route.authRequired && !user) {
        return {
          ...ROUTES.Login,
          name: route.name,
        };
      }
      return route;
    });

  function getRouteByName(name: string): Route | undefined {
    return Object.values(routes).find((route) => route.name === name);
  }

  return {
    routes,
    getRouteByName,
  };
}
