import { UserRepo } from 'ehawker/src/data/repositories/UserRepo';

import { queryClient } from '../../ui/App';

/**
 * List of data to delete when logout
 */
export function deleteData() {
  return Promise.all([
    UserRepo.setHeaderToken(null),
    queryClient.invalidateQueries(),
  ]);
}
