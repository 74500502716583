import { StyleProp, View, ViewStyle } from 'react-native';
import { Text } from 'react-native-paper';

import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryStack,
  VictoryTheme,
  VictoryZoomContainer,
} from '../../../components/victory/victory';

interface UsageChartProps {
  title: string;
  data: { x: string; y: number }[];
  containerStyle?: StyleProp<ViewStyle>;
}

export function UsageChart({ title, data, containerStyle }: UsageChartProps) {
  const xLabels = data.map((item) => item.x);
  const isAllZero = data.every((item) => item.y === 0);

  return (
    <View style={containerStyle}>
      <Text
        variant="bodyMedium"
        style={{
          alignSelf: 'center',
        }}
      >
        {title}
      </Text>
      <View
        style={{
          marginVertical: -30,
          marginTop: -50,
        }}
      >
        <VictoryChart
          theme={VictoryTheme.material}
          width={430}
          height={200}
          domainPadding={20}
          domain={isAllZero ? { y: [0, 2] } : undefined}
          // @ts-ignore
          containerComponent={<VictoryZoomContainer />}
        >
          <VictoryAxis dependentAxis />
          <VictoryAxis tickValues={xLabels} />
          <VictoryStack colorScale="green">
            <VictoryBar data={data} />
          </VictoryStack>
        </VictoryChart>
      </View>
    </View>
  );
}
