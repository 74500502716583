import { DrawerContentScrollView } from '@react-navigation/drawer';
import { CommonStyles } from 'base/src/ui/CommonStyles';
import { useTranslate } from 'base/src/ui/locale/locale';
import * as React from 'react';
import { Platform, View } from 'react-native';
import { Drawer as PaperDrawer, Modal, Portal } from 'react-native-paper';

import { useDrawer } from './useDrawer';

export function DashboardDrawer() {
  const { t } = useTranslate();
  const {
    drawerItems,
    selectedDrawerItem,
    selectDrawerItem,
    drawerOpen,
    setDrawerOpen,
  } = useDrawer();

  return (
    <Portal>
      <Modal
        visible={drawerOpen}
        onDismiss={() => setDrawerOpen(false)}
        contentContainerStyle={{
          height: '100%',
          width: '60%',
          paddingTop: Platform.OS === 'web' ? '20%' : '10%',
          backgroundColor: 'white',
        }}
      >
        <DrawerContentScrollView>
          <View style={CommonStyles.container}>
            {Object.entries(drawerItems).map(([key, value]) => (
              <PaperDrawer.Item
                key={key}
                icon={value.icon}
                label={t(value.title.toLowerCase())}
                active={isActive(key)}
                onPress={() => navigate(key)}
              />
            ))}
          </View>
        </DrawerContentScrollView>
      </Modal>
    </Portal>
  );

  function isActive(drawerItem: string) {
    return selectedDrawerItem === drawerItem;
  }

  function navigate(drawerItem: string) {
    selectDrawerItem(drawerItem);
    setDrawerOpen(false);
  }
}
