import { useTranslate } from 'base/src/ui/locale/locale';
import moment from 'moment';
import { Controller } from 'react-hook-form';
import { StyleProp, TextStyle } from 'react-native';
import { DatePickerInput } from 'react-native-paper-dates';

interface ControlledDateInputProps {
  name: string;
  label: string;
  style?: StyleProp<TextStyle>;
}

export function ControlledDateInput({
  name,
  label,
  style,
}: ControlledDateInputProps) {
  const { i18n, t } = useTranslate();
  return (
    <Controller
      render={({ field: { onChange, value } }) => (
        <DatePickerInput
          style={style}
          label={label}
          mode="outlined"
          value={value ? moment(value).toDate() : undefined}
          onChange={(newDate) =>
            onChange(
              newDate ? moment(newDate).format('YYYY-MM-DDTHH:mm') : undefined,
            )
          }
          inputMode="start"
          locale={i18n.language}
          withDateFormatInLabel={false}
        />
      )}
      name={name}
    />
  );
}
