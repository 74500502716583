import { useHeaderHeight } from '@react-navigation/elements';
import { CommonStyles } from 'base/src/ui/CommonStyles';
import { KeyboardDismissView } from 'base/src/ui/components/KeyboardDismissView';
import { PageContainer } from 'base/src/ui/components/PageContainer';
import { useTranslate } from 'base/src/ui/locale/locale';
import { loggerWithTag } from 'base/src/utils/log';
import Constants from 'expo-constants';
import * as SplashScreen from 'expo-splash-screen';
import { useSetAtom } from 'jotai';
import React, { useEffect } from 'react';
import { Dimensions, Image as RNImage, StyleSheet, View } from 'react-native';
import { Text, useTheme } from 'react-native-paper';

import { LoginForm } from './LoginForm';
import { navColorAtom } from '../../navigation/NavBar';

const log = loggerWithTag('LoginPage.tsx');

export const loginStyles = StyleSheet.create({
  outerContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  loginContainer: {
    width: '85%',
  },
  bottomContainer: {
    position: 'absolute',
    bottom: 0,
    alignItems: 'center',
    width: '100%',
  },
  textInput: {
    marginTop: 20,
    backgroundColor: 'transparent',
  },
  loginBtn: {
    marginTop: 20,
  },
});

export default function LoginPage() {
  log.debug('LoginPage rendered');
  const { t } = useTranslate();
  const { colors } = useTheme();
  const headerHeight = useHeaderHeight();
  const height = Dimensions.get('window').height;
  const backgroundColor = colors.background;

  const setNavColor = useSetAtom(navColorAtom);
  useEffect(() => {
    setNavColor(backgroundColor);
    return () => setNavColor('');
  }, [backgroundColor, setNavColor]);

  useEffect(() => {
    SplashScreen.hideAsync().catch(log.error);
  }, []);

  return (
    <KeyboardDismissView>
      <PageContainer
        customStyles={{
          backgroundColor: colors.background,
        }}
      >
        <View style={[CommonStyles.container, loginStyles.outerContainer]}>
          <RNImage
            style={{
              position: 'absolute',
              height: height < 700 ? 50 : 80,
              width: '85%',
              resizeMode: 'center',
              top: 0,
            }}
            source={require('../../../../assets/common/logo.png')}
          />
          <View
            style={{ ...loginStyles.loginContainer, marginTop: -headerHeight }}
          >
            <Text
              //variant="headlineLarge"
              style={{
                fontWeight: 'bold',
                fontSize: 50,
              }}
            >
              eInventory Order
            </Text>
            <LoginForm />
          </View>
          <View style={loginStyles.bottomContainer}>
            <Text variant="bodySmall">
              {t('version', {
                version: Constants.expoConfig?.extra?.version ?? '-',
                build: Constants.expoConfig?.extra?.build ?? '-',
              })}
            </Text>
            <Text variant="bodySmall">
              {t('copyright_line1', { year: new Date().getFullYear() }) +
                ' ' +
                t('copyright_line2')}
            </Text>
          </View>
        </View>
      </PageContainer>
    </KeyboardDismissView>
  );
}
