import { BottomSheetMethods } from '@devvie/bottom-sheet';
import { zodResolver } from '@hookform/resolvers/zod';
import { SPACING } from 'base/src/ui/CommonStyles';
import { PageContainer } from 'base/src/ui/components/PageContainer';
import { useTranslate } from 'base/src/ui/locale/locale';
import { OrderRepo } from 'ehawker/src/data/repositories/OrderRepo';
import {
  OrderListRequestSchema,
  OrderListRequestZodSchema,
  OrderListView,
} from 'ehawker/src/data/schemas/order/OrderListRequestSchema';
import { useHawkerUser } from 'ehawker/src/ui/hooks/useHawkerUser';
import { useOrderList } from 'ehawker/src/ui/query/useOrderList';
import { useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FlatList, View } from 'react-native';
import { Badge, Button, useTheme } from 'react-native-paper';

import { OrderListFilterSheet } from './OrderListFilterSheet';
import { OrderListItem } from './OrderListItem';
import { useDashboardTitle } from '../../hooks/useDashboardTitle';

export function OrderList() {
  const { colors } = useTheme();
  const { t } = useTranslate();
  const user = useHawkerUser();
  const emptyFilter = OrderListRequestZodSchema.parse({
    _view: OrderListView.My,
  });
  const form = useForm({
    resolver: zodResolver(OrderListRequestZodSchema),
    defaultValues: emptyFilter,
  });
  const [filter, setFilter] = useState<OrderListRequestSchema>(emptyFilter);
  const { orders, fetchMoreOrders } = useOrderList(filter);
  const sheetRef = useRef<BottomSheetMethods>(null);

  useDashboardTitle(t('order'));

  return (
    <PageContainer>
      <FormProvider {...form}>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <View />
          <View>
            <Button
              textColor={colors.secondary}
              icon="filter-variant"
              onPress={() => {
                form.reset(filter);
                sheetRef.current?.open();
              }}
            >
              {t('filter')}
            </Button>
            {OrderRepo.isEmptyOrderListFilter(filter) ? null : (
              <Badge
                style={{
                  position: 'absolute',
                  top: 8,
                  right: 8,
                }}
                size={6}
              />
            )}
          </View>
        </View>
        <FlatList
          style={{
            marginHorizontal: -SPACING.l,
            paddingHorizontal: SPACING.l,
            paddingVertical: 2,
          }}
          data={orders}
          onEndReached={() => fetchMoreOrders()}
          renderItem={({ item }) => <OrderListItem order={item} />}
        />
        <OrderListFilterSheet
          form={form}
          sheetRef={sheetRef}
          onApplyClicked={() => {
            setFilter(form.getValues());
            sheetRef.current?.close();
          }}
          onDismissClicked={() => sheetRef.current?.close()}
          onResetClicked={() => {
            form.reset(emptyFilter);
            form.setValue('_keyword', '');
          }}
        />
      </FormProvider>
    </PageContainer>
  );
}
