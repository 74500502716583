import { atom, useSetAtom } from 'jotai';
import { useEffect } from 'react';

interface NavButton {
  icon: string;
  onClick: () => void;
}

export const dashboardTitleAtom = atom('');

export function useDashboardTitle(title: string) {
  const setDashboardTitle = useSetAtom(dashboardTitleAtom);
  useEffect(() => {
    setDashboardTitle(title);
    return () => {
      setDashboardTitle('');
    };
  }, [title, setDashboardTitle]);
}
