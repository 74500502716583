import { MaterialCommunityIcons } from '@expo/vector-icons';
import { zodResolver } from '@hookform/resolvers/zod';
import { CommonStyles, SPACING } from 'base/src/ui/CommonStyles';
import { ExpandableView } from 'base/src/ui/components/ExpandableView';
import { PageContainer } from 'base/src/ui/components/PageContainer';
import { PasswordInput } from 'base/src/ui/components/input/PasswordInput';
import { useErrorHelper } from 'base/src/ui/helpers/ErrorHelper';
import { useSnackbar } from 'base/src/ui/helpers/SnackbarHelper';
import { useTranslate } from 'base/src/ui/locale/locale';
import { loggerWithTag } from 'base/src/utils/log';
import { HawkerUserRepo } from 'ehawker/src/data/repositories/HawkerUserRepo';
import {
  ChangePasswordRequest,
  ChangePasswordRequestSchema,
} from 'ehawker/src/data/schemas/auth/ChangePasswordRequestSchema';
import { useHawkerUser } from 'ehawker/src/ui/hooks/useHawkerUser';
import React, { useEffect, useState } from 'react';
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { View } from 'react-native';
import { Button, Card, Text, useTheme } from 'react-native-paper';

import { useDashboardTitle } from '../../hooks/useDashboardTitle';

const log = loggerWithTag('ProfilePage');

export function CollapsedView({
  onChangePasswordPressed,
}: {
  onChangePasswordPressed: () => void;
}) {
  log.debug('ProfilePage CollapsedView rendered');
  const { t } = useTranslate();
  return (
    <View>
      <Button mode="contained" onPress={onChangePasswordPressed}>
        {t('changepassword')}
      </Button>
    </View>
  );
}

export function ExpandedView({
  onCancelPressed,
}: {
  onCancelPressed: () => void;
  setChangePassword?: (value: boolean) => void;
}) {
  log.debug('ProfilePage ExpandedView rendered');
  const { t } = useTranslate();
  const form = useFormContext<ChangePasswordRequest>();
  const [submitting, setSubmitting] = useState(false);
  const { errorDialog } = useErrorHelper();
  const { addSnackbar } = useSnackbar();

  async function onSubmitPressed() {
    const isValid = await form.trigger();
    if (!isValid) return;
    try {
      setSubmitting(true);
      await HawkerUserRepo.changePassword(form.getValues());
      addSnackbar({
        message: t('password_changed'),
      });
      onCancelPressed();
      form.reset();
    } catch (e) {
      errorDialog(e as Error);
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <View
      style={{
        gap: SPACING.xs,
      }}
    >
      <View />
      <Controller
        control={form.control}
        render={({ field: { onChange, value } }) => (
          <PasswordInput
            label={t('current_password')}
            onChangeText={(text) => {
              onChange(text);
              form.clearErrors('_oldPassword');
            }}
            value={value ?? ''}
            inputError={form.formState.errors._oldPassword}
          />
        )}
        name="_oldPassword"
      />
      <Controller
        control={form.control}
        render={({ field: { onChange, value } }) => (
          <PasswordInput
            label={t('new_password')}
            onChangeText={(text) => {
              onChange(text);
              form.clearErrors('_newPassword');
            }}
            value={value ?? ''}
            inputError={form.formState.errors._newPassword}
          />
        )}
        name="_newPassword"
      />
      <Controller
        control={form.control}
        render={({ field: { onChange, value } }) => (
          <PasswordInput
            label={t('confirm_password')}
            onChangeText={(text) => {
              onChange(text);
              form.clearErrors('_confirmedPassword');
            }}
            value={value ?? ''}
            inputError={form.formState.errors._confirmedPassword}
          />
        )}
        name="_confirmedPassword"
      />
      <View
        style={[
          CommonStyles.row,
          CommonStyles.container,
          {
            gap: SPACING.s,
            marginTop: SPACING.xs,
          },
        ]}
      >
        <Button
          style={CommonStyles.container}
          mode="contained-tonal"
          onPress={onCancelPressed}
          disabled={submitting}
        >
          {t('cancel')}
        </Button>
        <Button
          style={CommonStyles.container}
          mode="contained"
          onPress={onSubmitPressed}
          loading={submitting}
          disabled={submitting}
        >
          {t('submit')}
        </Button>
      </View>
    </View>
  );
}

export function ProfilePage() {
  log.debug('ProfilePage rendered');
  const { t } = useTranslate();
  const { colors } = useTheme();
  const user = useHawkerUser();
  const [expanded, setExpanded] = useState(false);
  const form = useForm<ChangePasswordRequest>({
    resolver: zodResolver(ChangePasswordRequestSchema),
  });

  useDashboardTitle(t('profile'));

  useEffect(() => {
    HawkerUserRepo.afterLogin().catch(log.error);
  }, []);

  return (
    <PageContainer
      backgroundColor={colors.elevation.level0}
      customStyles={{
        paddingTop: SPACING.s,
        alignItems: 'center',
      }}
    >
      <MaterialCommunityIcons
        name="account"
        size={100}
        color={colors.outline}
      />
      <Card
        style={{
          width: '90%',
          backgroundColor: colors.background,
        }}
      >
        <Card.Content>
          <ProfileRow label={t('name')} value={String(user?._name ?? '-')} />
          <ProfileRow
            label={t('username')}
            value={String(user?._username ?? '-')}
          />
          <ProfileRow label={t('centre')} value={String(user?._storecentre)} />
          <ProfileRow label={t('store')} value={String(user?._store)} />
          <ProfileRow
            label={t('contact_no')}
            value={String(user?._contactNo)}
          />
          <FormProvider {...form}>
            <ExpandableView
              viewKey="profile-change-password"
              containerStyle={{ width: '100%' }}
              initialExpanded={false}
              expanded={expanded}
              expandedContent={
                <ExpandedView onCancelPressed={() => setExpanded(false)} />
              }
              collapsedContent={
                <CollapsedView
                  onChangePasswordPressed={() => setExpanded(true)}
                />
              }
            />
          </FormProvider>
        </Card.Content>
      </Card>
    </PageContainer>
  );
}

function ProfileRow({ label, value }: { label: string; value: string }) {
  const { colors } = useTheme();
  return (
    <>
      <Text style={{ color: colors.outline }} variant="labelLarge">
        {label}
      </Text>
      <Text variant="bodyLarge" style={{ marginBottom: SPACING.xs }}>
        {value}
      </Text>
    </>
  );
}
