import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { useWebBackButtonHandler } from 'ehawker/src/ui/pages/Order/hooks/useWebBackButtonHandler';
import { FunctionComponent, useCallback } from 'react';

import { NavBar, NavBarProps } from './NavBar';
import { useAppRoutes } from '../hooks/useAppRoutes';

const Stack = createNativeStackNavigator();

export function NavigatorWrapper() {
  const header = useCallback((props: NavBarProps) => <NavBar {...props} />, []);
  const { routes } = useAppRoutes();
  useWebBackButtonHandler();

  return (
    <Stack.Navigator
      initialRouteName={routes[0].name}
      screenOptions={{
        header,
      }}
    >
      {routes.map((route) => (
        <Stack.Screen
          key={route.name}
          name={route.name}
          component={route.component as FunctionComponent}
        />
      ))}
    </Stack.Navigator>
  );
}
